.mat-dialog-container {
    padding: 0 !important;
    position: relative;
    overflow: visible !important;
    font-family: Open Sans, sans-serif;
    .modal-container {
        max-width: 100%;
        width: 100%;
        >.mat-dialog-content {
            padding: 10px 32px 10px 32px;
            position: relative;
            margin: 0;
            .title {
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 24px;
                text-align: center;
                letter-spacing: 0.15px;
                color: $color-primary-azul-gnp;
                margin-bottom: 24px;
            }
            .mat-dialog-title {
                text-align-last: center;
            }
            .description {
                font-family: Open Sans, sans-serif;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 24px;
                letter-spacing: 0.5px;
                color: $color-inks-azul-gnp-800;
            }
        }
    }
    .actions-container {
        border-top: solid 1px $color-background-lighter;
        justify-content: center;
    }
    .mat-dialog-actions {
        padding: 0 24px !important;
        margin: 0 !important;
        margin: 0 !important;
    }
}

.close-modal {
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: #fff;
    outline: none;
    cursor: pointer;
    border: 1px solid #a3aab6;
    position: absolute;
    top: -8px;
    right: -8px;
    z-index: 1;
}
.date-picker {
    box-shadow: none !important;

    .bs-datepicker-head {
        background-color: #fff;

        .previous,
        .next {
            color: $color-inks-naranja-gnp;
            padding: 0 !important;
        }

        .current {
            color: $color-inks-azul-gnp-800;
            text-transform: capitalize;
        }
    }

    .bs-datepicker-container {
        padding: 0;
    }

    .bs-datepicker-multiple {
        margin: 0 !important;
    }

    .bs-datepicker-body {
        table {
            td,
            td span {
                color: $color-background-darkest;
            }

            td span.selected,
            td.selected span,
            td span[class*='select-']:after,
            td[class*='select-'] span:after {
                background-color: $color-inks-naranja-gnp;
                width: auto;
                height: auto;
                line-height: 25px;
            }

            td span.select-start {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
                color: #fff;
            }

            td span.select-end {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
                color: #fff;
            }

            td span.is-highlighted {
                background-color: #fff !important;
                border-radius: 50%;
                transition: 0s;
                border: solid 1px $color-inks-naranja-gnp;
            }

            td:before,
            td span:before {
                top: 3px;
                bottom: 4px;
            }

            &.days {
                span.in-range:not(.select-start):before {
                    background-color: $color-inks-naranja-gnp-100;
                }
            }
        }
    }
}

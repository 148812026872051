/**
 * Style for MatDrawer
 */

.mat-drawer {
    @include respond-to(small-screens) {
        width: 100%;
    }
}

.mat-drawer-backdrop.mat-drawer-shown {
    background-color: transparent;
}

.mat-drawer-inner-container {
    position: relative;
}

/**
 * Form fields
 */
.mat-form-field-label {
    font-family: Open Sans !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 24px !important;
    letter-spacing: 0.5px !important;
    color: $color-inks-azul-gnp-800 !important;
    mix-blend-mode: normal !important;
}

.mat-form-field.mat-focused .mat-form-field-label {
    color: $color-background-dark !important;
}

.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
    background-color: $color-background-lightest;
}

.mat-form-field-appearance-outline .mat-form-field-infix,
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
    padding-top: 0.5em;
}

.mat-form-field-appearance-fill .mat-form-field-infix {
    padding-bottom: 1em;
}

.mat-option,
.mat-select-value,
mat-form-field .mat-input-element {
    color: #0C2040 !important;
    font-size: 16px !important; 
}

mat-form-field mat-label {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    line-height: 24px;
    letter-spacing: 0.5px;
    mix-blend-mode: normal;
    color: $color-inks-azul-gnp-800;
    font-size: 16px;
}

.mat-select-disabled .mat-select-value,
.mat-select-placeholder,
mat-select mat-label {
    color: $color-background-dark;
}

.mat-form-field-appearance-outline .mat-form-field-outline {
    box-shadow: 0px 0px 1px $color-background-light;
    color: $color-background-light;
}

.mat-form-field-appearance-legacy .mat-form-field-underline {
    background-color: $color-inks-azul-gnp-200;
}

.mat-form-field-appearance-outline .mat-select-arrow-wrapper {
    transform: translateY(-5%) !important;
}

.mat-form-field-appearance-fill .mat-select-arrow-wrapper {
    transform: translateY(-25%) !important;
}

.mat-checkbox-layout {
    white-space: normal !important;
}

.full-size {
    width: 100%;
}

mat-label {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    line-height: 24px;
    letter-spacing: 0.5px;
    mix-blend-mode: normal;
    color: $color-inks-azul-gnp-800;
    font-size: 16px;
}

/**
 * Mat card
 */
.mat-card:not([class*='mat-elevation-z']) {
    box-shadow: 0px 2px 4px $color-background-lighter,
        0px 3px 4px $color-background-lighter,
        0px 1px 5px $color-background-lighter;
}

.mat-card-header .mat-card-header-text {
    margin-left: 0 !important;
}

.mat-card-header .mat-card-header-text {
    width: 100%;
}

/**
 * Focused buttons
 */
.mat-list-single-selected-option,
.mat-list-single-selected-option:hover,
.mat-list-single-selected-option:focus {
    background: transparent;
}

/**
 * Progress bar
 */
.mat-progress-bar.mat-accent .mat-progress-bar-buffer {
    background-color: $color-inks-azul-gnp-400;
}

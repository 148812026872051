.arrow {
    border: solid #647085;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 2.5px;

    &.-right {
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
    }

    &.-left {
        transform: rotate(135deg);
        -webkit-transform: rotate(135deg);
    }

    &.-up {
        transform: rotate(-135deg);
        -webkit-transform: rotate(-135deg);
    }

    &.-down {
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
    }
}

.toggle-group {
    border: none;

    > .panel-heading {
        background: #f6f9fd;
        border: 1px solid #e8eef8;
        box-sizing: border-box;
        border-radius: 4px;
        height: 48px;
        display: flex;
        align-items: center;
        padding: 0 24px;

        .heading {
            font-family: Open Sans, sans-serif;
            display: flex;
            align-items: center;
            font-weight: 600;
            font-size: 14px;
            line-height: 24px;
            letter-spacing: 0.15px;
            color: #002e71;

            > .icon {
                color: $color-inks-naranja-gnp;
                font-size: 24px;
                margin-right: 24px;
                transition: all 0.4s ease-in-out;
            }
        }
    }

    .panel-body {
        padding: 16px 0;
    }
}

.panel-open {
    .toggle-group {
        .heading {
            > .icon {
                -webkit-transform: rotate(180deg);
                transform: rotate(180deg);
                -webkit-transition: 0.4s ease-in-out;
                transition: all 0.4s ease-in-out;
            }
        }
    }
}

@import 'mixins';
@import './theme.scss';
@import '~@angular/material/theming';
@import 'assets/scss/main';
@import 'utilities/spacing';
@include mat-core();
$color-contrast: ( 50: $color-primary-humo, 100: $color-primary-humo, 200: $color-primary-humo, 300: $color-primary-humo, 400: $color-primary-humo, 500: $color-primary-humo, 600: $color-primary-humo, 700: $color-primary-humo, 800: $color-primary-humo, 900: $color-primary-humo, A100: $color-primary-humo, A200: $color-primary-humo, A400: $color-primary-humo, A700: $color-primary-humo);
$gnp-orange: ( 50: $color-inks-naranja-gnp-100, 100: $color-inks-naranja-gnp-100, 200: $color-inks-naranja-gnp-200, 300: $color-inks-naranja-gnp-300, 400: $color-inks-naranja-gnp-400, 500: $color-inks-naranja-gnp, 600: $color-inks-naranja-gnp-500, 700: $color-inks-naranja-gnp-600, 800: $color-inks-naranja-gnp-700, 900: $color-inks-naranja-gnp-800, A100: $color-inks-naranja-gnp, A200: $color-inks-naranja-gnp, A400: $color-inks-naranja-gnp, A700: $color-inks-naranja-gnp, contrast: $color-contrast);
$gnp-blue: ( 50: $color-inks-azul-gnp-50, 100: $color-inks-azul-gnp-100, 200: $color-inks-azul-gnp-200, 300: $color-inks-azul-gnp-300, 400: $color-inks-azul-gnp-400, 500: $color-inks-azul-gnp, 600: $color-inks-azul-gnp-500, 700: $color-inks-azul-gnp-600, 800: $color-inks-azul-gnp-800, 900: $color-inks-azul-gnp-900, A100: $color-inks-azul-gnp, A200: $color-inks-azul-gnp, A400: $color-inks-azul-gnp, A700: $color-inks-azul-gnp, contrast: $color-contrast);
$gnp-primary: mat-palette($gnp-orange);
$gnp-accent: mat-palette($gnp-blue, A200, A100, A400);
$gnp-warn: mat-palette($mat-red);
$gnp-theme: mat-light-theme( ( color: ( primary: $gnp-primary, accent: $gnp-accent, warn: $gnp-warn)));
@include angular-material-theme($gnp-theme);
@font-face {
    font-family: Open Sans;
    src: url(./assets/fonts/OpenSans-Regular.ttf) format('truetype');
    font-weight: lighter;
}

@font-face {
    font-family: Open Sans;
    src: url(./assets/fonts/OpenSans-SemiBold.ttf) format('truetype');
    font-weight: normal;
}

@font-face {
    font-family: Open Sans;
    src: url(./assets/fonts/OpenSans-Bold.ttf) format('truetype');
    font-weight: bold;
}

body {
    height: 100%;
    margin: 0;
}

* {
    font-family: Open Sans;
    font-style: normal;
}


/**
 * Material components edited
 */

@import 'assets/scss/material-components';

/**
 * General style's
 */

.title-app {
    font-weight: 600;
    font-size: 20px;
    line-height: 40px;
    letter-spacing: 0.15px;
    color: $color-primary-azul-gnp;
}

.title-section {
    font-size: 18px;
    color: $color-primary-naranja-gnp;
    font-weight: bold;
}

.subtitle-section {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: $color-background-darker;
}

// Link
.link-mat,
.btn-link {
    color: $color-inks-naranja-gnp;
    &:hover {
        color: $color-inks-naranja-gnp-800;
    }
}

// Primary button
.btn-primary {
    background-color: $color-inks-naranja-gnp;
    color: white;
}

// Primary button
.btn-secondary {
    background-color: $color-inks-azul-gnp;
    color: white;
}

.btn-primary-outline,
.mat-button.btn-primary-outline {
    border: $color-inks-naranja-gnp 1px solid;
    background-color: white;
    color: $color-inks-naranja-gnp;
}

.mat-button.mat-button-disabled.mat-button-disabled {
    color: white;
    opacity: 0.6;
}

.hint {
    font-size: 10px;
    line-height: 13px;
    letter-spacing: 0.1px;
    color: $color-background-dark;
    mix-blend-mode: normal;
    &.-error {
        color: red !important;
    }
}

.mat-option:hover:not(.mat-option-disabled),
.mat-option:focus:not(.mat-option-disabled) {
    background-color: $color-primary-marfil;
    color: $color-inks-naranja-gnp;
}

.mat-form-field-appearance-legacy .mat-form-field-infix {
    padding: 13px 0;
}

.mat-form-field-label {
    font-family: Open Sans !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 14px !important;
    line-height: 24px !important;
    letter-spacing: 0.5px !important;
    color: #33445f !important;
    mix-blend-mode: normal !important;
}

.mat-form-field.mat-focused .mat-form-field-label {
    color: #647085 !important;
}

.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
    background-color: #f6f9fd;
}

.mat-form-field-appearance-outline .mat-form-field-infix,
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
    padding-top: 0.5em;
}

.mat-form-field-appearance-fill .mat-form-field-infix {
    padding-bottom: 1em;
}

.mat-option,
.mat-select-value,
mat-form-field .mat-input-element {
    color: #0C2040 !important;
    font-size: 16px !important;
}

mat-form-field mat-label {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    line-height: 24px;
    letter-spacing: 0.5px;
    mix-blend-mode: normal;
    color: #33445f;
    font-size: 14px;
}

.mat-select-disabled .mat-select-value,
.mat-select-placeholder,
mat-select mat-label {
    color: #647085;
}

.mat-form-field-appearance-outline .mat-form-field-outline {
    box-shadow: 0px 0px 1px #e0eaf8;
    color: #e6eaf0;
}

.mat-form-field-appearance-legacy .mat-form-field-underline {
    background-color: #aac3ee;
}

.mat-form-field-appearance-outline .mat-select-arrow-wrapper {
    transform: translateY(-5%) !important;
}

.mat-form-field-appearance-fill .mat-select-arrow-wrapper {
    transform: translateY(-25%) !important;
}

.mat-checkbox-layout {
    white-space: normal !important;
}

.mat-button mat-icon {
    font-size: 20px;
}

.tooltip-application-gnp {
    text-align: center;
    font-size: 13px !important;
    background-color: #567099;
    width: 500px !important;
    max-width: 500px !important;
    height: auto;
    overflow: initial !important;
    @include respond-to(small-screens) {
        width: 60% !important;
        max-width: 60% !important;
    }
}

.mat-paginator {
    color: #ff6b0b;
    .mat-paginator-range-label {
        color: #647085;
    }
}

.actions-container {
    display: flex;
    align-items: center;
    >.action {
        min-width: 96px;
        height: 36px;
        font-family: Open Sans;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        /* identical to box height, or 125% */
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: 1.25px;
        margin-left: 16px;
        @include respond-to(small-screens) {
            margin-right: 0;
            width: 100%;
            min-width: 100%;
            display: block;
            text-align: center;
            margin-left: 0;
            margin-bottom: 8px;
        }
    }
    .action,
    .action.button-outline {
        box-shadow: 0px 0px 2px #ecf1f8, 0px 2px 2px #ecf1f8, 0px 1px 3px #ecf1f8 !important;
    }
}

.uno {
    background-color: green;
}

.dos {
    background-color: cyan;
}

.tres {
    background-color: orange;
}

hr.solid {
    border-top: 1px #ced8e8;
}

@media (min-width: 992px) {
    .container-lg,
    .container-md,
    .container-sm,
    .container {
        min-width: 100%;
        justify-content: left;
        text-align: left;
    }
}

@media (min-width: 0px) {
    .container-lg,
    .container-md,
    .container-sm,
    .container {
        min-width: 100%;
    }
}

@include respond-to(small-screens) {
    .table-reponsive {
        width: 100%;
        overflow-x: hidden;
    }
    .btn-block-style {
        width: 100%;
        display: block;
    }
}

.icon-button {
    width: 18px;
}

mat-select.no-arrow {
    .mat-select-arrow {
        color: transparent !important;
    }
}

.mat-calendar-body-label {
    opacity: 0 !important;
    padding: 0 !important;
}


/***
 * Estilos del los inputs (cotizador)
 **/

.img-date-picker .mat-form-field-suffix,
.img-date-picker-bootstrap .mat-form-field-suffix {
    background-image: url('./assets/img/calendar_today_24px.png');
    background-repeat: no-repeat;
    background-size: 70%;
    width: 30px;
    background-position-y: -1px;
}

.mat-datepicker-toggle button mat-icon,
.mat-form-field-suffix mat-icon {
    font-size: 20px !important;
    color: #647085;
}

.img-date-picker .mat-form-field-suffix mat-icon {
    display: none;
}

.img-date-picker-bootstrap .mat-form-field-suffix mat-icon {
    opacity: 0;
}

.mat-form-field-flex {
    padding-left: 12px;
}

.bg-select-gray.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
    background-color: rgba(163, 170, 182, 0.1);
}

.bg-select-gray.mat-form-field-appearance-fill .mat-input-element:disabled {
    color: #0C2040 !important;
    margin-top: 3px;
    font-size: 16px !important;
}


/**
  * Estilos de modal
  */

.mat-dialog-container .modal-container>.mat-dialog-content .title.title-figma {
    /* Subtitle 1 / Open Sans Regular */
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 150% */
    text-align: center;
    letter-spacing: 0.15px;
    /* Primary — 700* */
    color: #002e71;
}

.mat-dialog-container .modal-container>.mat-dialog-content .description.description-figma {
    /* Body 1 / Open Sans Regular */
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    /* or 150% */
    letter-spacing: 0.5px;
    /* GNP_Text_System */
    color: #33445f;
}

.mat-chip.mat-standard-chip .mat-chip-remove {
    color: #647085;
    opacity: 1;
}

mat-chip .chip-item {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 171% */
    display: flex;
    align-items: center;
    letter-spacing: 0.25px;
    /* GNP_Text_System */
    color: #33445f;
    mix-blend-mode: normal;
}

.hint-error.mat-error {
    color: #f44336;
    padding-bottom: 5px;
}

.class-input-currency.mat-form-field-disabled {
    .mat-form-field-subscript-wrapper {
        padding: 0 !important;
    }
}

.hint-error-message {
    font-family: Open Sans, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 13px;
    letter-spacing: 0.1px;
    color: #f34336;
    mix-blend-mode: normal;
}

.mat-select-panel .mat-optgroup-label,
.mat-select-panel .mat-option .mat-option-text {
    font-size: 14px;
}

.center-div {
    display: flex;
}

.hidePanel {
    display: none;
}

.searchIcon {
    cursor: pointer;
    max-width: 18px;
    max-height: 18px;
}

.mat-checkbox-inner-container {
    margin: 6px 8px auto 0 !important;
}